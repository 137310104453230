// https://paul.kinlan.me/screen-recorderrecording-microphone-and-the-desktop-audio-at-the-same-time/

// https://www.webrtc-experiment.com/getDisplayMedia/

let blobs = [];
let rec;
let stream;
let voiceStream;
let desktopStream;

const displayConstraints = {
	video: {
		aspectRatio: 1920 / 1080,
		frameRate: 30,
		cursor: 'never',
		width: 3840,
		height: 2160
	},
	audio: false
}

window.ScreenRecorder = {

	init: function () {
		this.context = new AudioContext({ sampleRate: 44100 });
		this.source = this.context.createBufferSource();
		this.destination = this.context.createMediaStreamDestination();
		this.voiceGain = this.context.createGain();
		this.source.connect(this.voiceGain);
		this.voiceGain.connect(this.destination);
	},

	start: async function () {
		desktopStream = await navigator.mediaDevices.getDisplayMedia(displayConstraints);
		voiceStream = this.destination.stream;
		this.source.start();

		const tracks = [
			...desktopStream.getTracks(),
			...voiceStream.getTracks()
		];

		stream = new MediaStream(tracks);
		rec = new MediaRecorder(stream, { mimeType: 'video/webm; codecs=vp8,opus' });
		rec.ondataavailable = (e) => blobs.push(e.data);
		rec.onstop = async () => {
			Downloader.download(blobs);
			AwesomeAudioScene.stopRender();
			AwesomeAudioControls.playing = false;
		}

		AwesomeAudioPlayer.play();
		rec.start();
	},

	stop: function () {
		rec.stop();
		stream.getTracks().forEach(track => track.stop())
		stream = null;
	}
}
